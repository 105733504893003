export const jsonData = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-98.02745, 30.2467]
      },
      'properties': {
        'phoneFormatted': '(512) 601-4209',
        'phone': '5126014209',
        'address': '15210 Fitzhugh Rd, Suite 900',
        'city': 'Austin',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '78736',
        'state': 'TX',
        'showroom': 'yes',
        'key': 0,
      }
    },
    
        
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-97.060930, 32.929110]
      },
      'properties': {
        'phoneFormatted': '(972) 355-7687',
        'phone': '9723557687',
        'address': '1240 Texan Trail, Suite 105,',
        'city': 'Dallas ',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '76051',
        'state': 'TX',
        'showroom': 'yes',
        'key': 1,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-105.007400, 39.705760]
      },
      'properties': {
        'phoneFormatted': '(303) 578-4314',
        'phone': '3035784314',
        'address': '590 Quivas St,',
        'city': 'Denver ',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '80204',
        'state': 'CO',
        'showroom': 'yes',
        'key': 2,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-104.818000, 38.923390]
      },
      'properties': {
        'phoneFormatted': '(719) 428-4664',
        'phone': '7194284664',
        'address': '6380 Corporate Centre Circle,',
        'city': 'Colorado Springs ',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '80919',
        'state': 'CO',
        'showroom': 'yes',
        'key': 3,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-106.788180, 32.304380]
      },
      'properties': {
        'phoneFormatted': '(575) 689-6067',
        'phone': '5756896067',
        'address': '1570 W Picacho Ave, Suite D,',
        'city': 'Las Cruces ',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '99005',
        'state': 'NM',
        'showroom': 'yes',
        'key': 4,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-115.199190, 36.105450]
      },
      'properties': {
        'phoneFormatted': '(702) 207-2201',
        'phone': '7022072201',
        'address': '4630 Arville Street Suite E & F,',
        'city': 'Las Vegas ',
        'country': 'United States',
        'postalCode': '89103',
        'state': 'NV',
        'showroom': 'yes',
        'key': 6,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-111.890390, 40.614250]
      },
      'properties': {
        'phoneFormatted': '(801) 701-1124',
        'phone': '8017011124',
        'address': '7515 S State St,',
        'city': 'Salt Lake City ',
        'country': 'United States',
        'postalCode': '84047',
        'state': 'UT',
        'showroom': 'yes',
        'key': 7
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-113.575680, 37.086300]
      },
      'properties': {
        'phoneFormatted': '435-465-0163',
        'phone': '',
        'address': '148 W Industrial Dr Unit 175',
        'city': 'St George',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '84780',
        'state': 'UT',
        'showroom': 'yes',
        'key': 8,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-110.991850, 32.246190]
      },
      'properties': {
        'phoneFormatted': '(520) 326-7888',
        'phone': '5203267888',
        'address': '2010 N. Forbes Blvd,',
        'city': 'Tucson',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '85745',
        'state': 'AZ',
        'showroom': 'yes',
        'key': 9,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-111.918180, 33.305970]
      },
      'properties': {
        'phoneFormatted': '(480) 967-2200',
        'phone': '4809672200',
        'address': '4320 W Chandler Blvd,',
        'city': 'Chandler',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '85226',
        'state': 'AZ',
        'showroom': 'yes',
        'key': 10,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-111.924240, 33.628240]
      },
      'properties': {
        'phoneFormatted': '(480) 967-2200',
        'phone': '4809672200',
        'address': '15507 N Scottsdale Rd #105,',
        'city': 'Scottsdale',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '85254',
        'state': 'AZ',
        'showroom': 'yes',
        'key': 11,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-111.651299, 35.198284]
      },
      'properties': {
        'phoneFormatted': '(928) 228-0008',
        'phone': '9282280008',
        'address': '',
        'city': 'Flagstaff ',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '',
        'state': 'AZ',
        'showroom': 'no',
        'key': 12,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-114.348953, 34.496140]
      },
      'properties': {
        'phoneFormatted': '(928) 453-8484',
        'phone': '9284538484',
        'address': '',
        'city': 'Lake Havasu',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '',
        'state': 'AZ',
        'showroom': 'no',
        'key': 15,
        'dealer': 'yes',
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-117.184100, 33.146220]
      },
      'properties': {
        'phoneFormatted': '(760) 735-2545',
        'phone': '7607352545',
        'address': '242 Bingham Dr #102,',
        'city': 'San Diego',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '92069',
        'state': 'CA',
        'showroom': 'yes',
        'key': 16,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-111.306023, 47.506451]
      },
      'properties': {
        'phoneFormatted': '(406) 836-7299',
        'phone': '4068367299',
        'address': '',
        'city': 'Great Falls',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '59401',
        'state': 'MT',
        'showroom': 'no',
        'key': 18,
        'dealer': 'no',
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-112.468712, 34.541851]
      },
      'properties': {
        'phoneFormatted': '(928) 228-0008',
        'phone': '9282280008',
        'address': '',
        'city': 'Prescott',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '85541, 85544',
        'state': 'AZ',
        'showroom': 'no',
        'key': 19,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-99.915512, 39.679390]
      },
      'properties': {
        'phoneFormatted': '(785) 871-0363',
        'phone': '7858710363',
        'address': '',
        'city': 'Norton',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '67654',
        'state': 'KS',
        'showroom': 'no',
        'key': 20,
        'dealer': 'yes',
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-106.402210, 31.792620]
      },
      'properties': {
        'phoneFormatted': '(915) 348-4820',
        'phone': '9153484820',
        'address': '',
        'city': 'El Paso ',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '79925',
        'state': 'TX',
        'showroom': 'no',
        'key': 22,
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-100.437035, 31.463772]
      },
      'properties': {
        'phoneFormatted': '(325) 949-1609',
        'phone': '3259491609',
        'address': '',
        'city': 'San Angelo',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '76904',
        'state': 'TX',
        'showroom': 'no',
        'key': 23,
        'dealer': 'yes',
      }
    },

    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-110.762352, 43.479622]
      },
      'properties': {
        'phoneFormatted': '(970) 819-1296',
        'phone': '9708191296',
        'address': '',
        'city': 'Jackson',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '',
        'state': 'WY',
        'showroom': 'no',
        'key': 25,
        'dealer': 'yes',
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-117.724760, 33.628530]
      },
      'properties': {
        'phoneFormatted': '(949) 249-5600',
        'phone': '9492495600',
        'address': '23024 Lake Forest Dr. Suite K',
        'city': 'Laguna Hills',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '',
        'state': 'CA',
        'showroom': 'yes',
        'key': 27,
        'dealer': 'no',
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [-95.467250, 30.158740]
      },
      'properties': {
        'phoneFormatted': '(281) 519-2211',
        'phone': '2815192211',
        'address': '2319 Timberloch Place Suite C',
        'city': 'The Woodlands',
        'country': 'United States',
        'crossStreet': '',
        'postalCode': '77380',
        'state': 'TX',
        'showroom': 'yes',
        'key': 28,
        'dealer': 'no',
        'comingSoon': false
      }
    },
  ]
}; 


export const showrooms = [
  {
    "address": "15210 Fitzhugh Rd, Suite 900, Austin, TX",
    "latLong": [30.2467, -98.02745]
  },
  {
    "address": "1240 Texan Trail Suite 105, Dallas, TX",
    "latLong": [32.92911, -97.06093]
  },
  {
    "address": "590 Quivas St, Denver, CO",
    "latLong": [39.70576, -105.0074]
  },
  {
    "address": "6380 Corporate Centre Circle, Colorado Springs, CO",
    "latLong": [38.92339, -104.818]
  },
  {
    "address": "1570 W Picacho Ave Suite D, Las Cruces, NM",
    "latLong": [32.30438, -106.78818]
  },
  {
    "address": "4630 Arville Street Suite E & F, Las Vegas, NV",
    "latLong": [36.10545, -115.19919]
  },
  {
    "address": "7515 S State St, Salt Lake City, UT",
    "latLong": [40.61425, -111.89039]
  },
  {
    "address": "148 W Industrial Dr Unit 175, St George, UT",
    "latLong": [37.0863, -113.57568]
  },
  {
    "address": "2010 N. Forbes Blvd,, Tucson, AZ",
    "latLong": [32.24619, -110.99185]
  },
  {
    "address": "4320 W Chandler Blvd,, Chandler, AZ",
    "latLong": [33.30597, -111.91818]
  },
  {
    "address": "15507 N Scottsdale Rd #105, Scottsdale, AZ",
    "latLong": [33.62824, -111.92424]
  },
  {
    "address": "242 Bingham Dr #102, San Diego, CA",
    "latLong": [33.14622, -117.1841]
  },
  {
    "address": "23024 Lake Forest Dr. Suite K, Laguna Hills, CA",
    "latLong": [33.62853, -117.72476]
  },
  {
    "address": "2319 Timberloch Place Suite C, The Woodlands, TX",
    "latLong": [30.15874, -95.46725]
  }
];

const orderStores = (data) => {
  let hashMap = {}

  data.features.forEach(store => {
    let state = store.properties.state;
    if (hashMap[state]) {
      hashMap[state].push(store)
      return
    }
    hashMap[state] = []
    hashMap[state].push(store)
  })

  let keys = Object.keys(hashMap).sort();
  keys.map(state => {
    hashMap[state].sort((a, b) => {
      let first = a.properties.city; let second = b.properties.city;
      return first.toLowerCase().localeCompare(second.toLowerCase())
    })
  })

  let arrayOfLocations = [];
  keys.map(state => {
    hashMap[state].forEach(x => arrayOfLocations.push(x))
  })

  return arrayOfLocations
}

export const stores = orderStores(jsonData);